import React from 'react';

import Event from '$components/events/event';

const colorClasses = [
  'bg-violet-100',
  'bg-red-100',
  'bg-pink-100',
  'bg-blue-100'
];

const dummyData = [
  {
    title: 'blood donation camp',
    startTime: '09:30 AM',
    date: new Date().toISOString(),
    organizer: 'Amin Painter'
  },
  {
    title: 'blood donation camp',
    startTime: '09:30 AM',
    date: new Date().toISOString(),
    organizer: 'Amin Painter'
  },
  {
    title: 'blood donation camp',
    startTime: '09:30 AM',
    date: new Date().toISOString(),
    organizer: 'Amin Painter'
  },
  {
    title: 'blood donation camp',
    startTime: '09:30 AM',
    date: new Date().toISOString(),
    organizer: 'Amin Painter'
  }
];

const EventsPanel = () => (
  <div className='space-y-6 mt-12'>
    {dummyData.map((d, i) => (
      <Event key={i} {...d} color={colorClasses[i % colorClasses.length]} />
    ))}
  </div>
);

export default EventsPanel;

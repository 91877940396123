import React, { useMemo } from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import { getComplaintStatistics } from '$utils/queryFunctions';

const Chart = () => {
  const { data: stats } = useQuery('statistics', getComplaintStatistics, {
    cacheTime: Infinity,
    staleTime: Infinity
  });

  const chartData = useMemo(
    () => ({
      labels: stats?.map(group => group._id),
      datasets: [
        {
          label: 'Complaints (FY-2022)',
          data: stats?.map(group => group.count),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(150, 75, 0, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(150, 75, 0)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }
      ]
    }),
    [stats]
  );

  return <Bar data={chartData} />;
};

export default Chart;

import classNames from 'classnames';
import React from 'react';

const Heading = ({ children, className, variant = 'secondary' }) => {
  if (variant === 'primary')
    return (
      <h1
        className={classNames(
          'text-black text-4xl leading-snug phone:text-3xl',
          className
        )}>
        {children}
      </h1>
    );

  if (variant === 'primary-white')
    return (
      <h1
        className={classNames(
          'text-white text-4xl leading-snug phone:text-3xl',
          className
        )}>
        {children}
      </h1>
    );

  if (variant === 'secondary')
    return (
      <h2 className={classNames('text-black text-4xl font-extrabold text-center', className)}>
        {children}
      </h2>
    );

  if (variant === 'secondary-white')
    return (
      <h2 className={classNames('text-white text-3xl text-center', className)}>
        {children}
      </h2>
    );

  if (variant === 'tertiary')
    return (
      <h3
        className={classNames(
          'text-black text-2xl text-center uppercase',
          className
        )}>
        {children}
      </h3>
    );

  if (variant === 'tertiary-left')
    return (
      <h2
        className={classNames('text-black text-2xl font-semibold', className)}>
        {children}
      </h2>
    );

  if (variant === 'tertiary-white')
    return (
      <h3 className={classNames('text-white text-2xl uppercase', className)}>
        {children}
      </h3>
    );
};

export default Heading;

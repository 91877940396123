import React from 'react';

const Stat = ({ title, count, icon: Icon }) => (
  <div className='text-black flex flex-col items-center space-y-1 text-center'>
    <div className='h-14 w-14 bg-primary rounded-full flex items-center justify-center phone:h-12 phone:w-12'>
      <Icon className='text-white fill-current phone:w-6 phone:h-6' />
    </div>
    <h3 className='text-2xl font-semibold'>{count}</h3>
    <h5>
      <span className='phone:block'>{title}</span> complaints
    </h5>
  </div>
);

export default Stat;

import React from "react";

import Heading from "$ui/heading";

import AdminLayout from "$components/layout/admin";
import Stat from "$components/admin/stat";
import EventsPanel from "$components/events/eventsPanel";
import Chart from "$components/admin/chart";

import { useUser } from "$hooks/auth";

import testIcon from "$assets/user.svg";

const DashboardPage = () => {
  const { user } = useUser();

  return (
    <AdminLayout title="Admin Dashboard">
      <section className="px-10 py-4 phone:px-5">
        <Heading variant="tertiary-left">
          Welcome, {user?.fullName.split(" ")[0]}!{" "}
          <span className="text-xs">({user?.role})</span>
        </Heading>

        <p className="text-xs">
          Lets fulfill your responsibilities as an admin of Consumer Rights
          Organization.
        </p>

        <div className="bg-primary bg-opacity-30 py-3 px-8 rounded-2xl text-white my-5 grid grid-cols-3 phone:px-4">
          <Stat icon={testIcon} title="Open" count={30} />
          <Stat icon={testIcon} title="Solved" count={30} />
          <Stat icon={testIcon} title="Pending" count={30} />
        </div>

        <div>
          <Heading variant="tertiary-left">Complaint Analytics</Heading>
          <Chart />
        </div>
      </section>

      {/* <section className="bg-white py-10 px-4">
        <Heading variant="tertiary-left">Upcoming events</Heading>

        <EventsPanel />
      </section> */}
    </AdminLayout>
  );
};

export default DashboardPage;
